import { PaymentTypes } from 'types'

const createLookupObjectFromEpi = (types: PaymentTypes) => {
  type lookup = Record<
    PaymentTypes[number]['paymentTypeKey'],
    PaymentTypes[number]['readablePaymentType']
  >
  return types.reduce((acc: lookup, type) => {
    return {
      ...acc,
      [type.paymentTypeKey]: type.readablePaymentType,
    }
  }, {})
}

export const hasPaymentTypeConfiguredInEpi = (
  type: PaymentTypes[number]['paymentTypeKey'],
  epiPaymentTypes: PaymentTypes
) => epiPaymentTypes.some((epiType) => epiType.paymentTypeKey === type)

export const getPaymentType = (
  paymentType: PaymentTypes[number]['paymentTypeKey'],
  epiPaymentTypes: PaymentTypes
) => createLookupObjectFromEpi(epiPaymentTypes)?.[paymentType] || ''
