import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '@trr/app-shell-data'
import { GetPaymentsResponse, Payment, PaymentTypes } from 'types'
import { getConfig, getPaymentType, hasPaymentTypeConfiguredInEpi } from 'utils'

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getCommingPayments: builder.query<
      Payment[],
      { sub: string | null; paymentTypes: PaymentTypes }
    >({
      query: ({ sub }) =>
        `/ekonomisktstod/utbetalningsunderlag/klient/${sub ?? ''}?page=1&pageSize=1&utbetalningsperiod=kommande`,
      transformResponse: (
        response: GetPaymentsResponse,
        _,
        { paymentTypes }
      ) => {
        if ('utbetalningsunderlag' in response) {
          return response.utbetalningsunderlag
            .filter((i) => hasPaymentTypeConfiguredInEpi(i.typ, paymentTypes))
            .map((i) => ({
              ...i,
              typ: getPaymentType(i.typ, paymentTypes),
            }))
        }
        return []
      },
    }),
  }),
})

export const { useGetCommingPaymentsQuery } = api

export default api
